.burger-menu {
    display: none; 
    z-index: 1000;
}

.burger-icon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 30px;
    margin: 10px;
}

.bar {
    width: 30px;
    height: 4px;
    background-color: #333;
    margin: 4px 0;
    transition: 0.4s;
}

.bar.open {
    transform: rotate(-45deg) translate(-4px, 6px);
}

.menu-items {
    display: flex;
    text-align: center;
    line-height: 80px;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   
    
}

.menu-popup {
    /* ... existing properties ... */
    opacity: 0; /* Initially hidden */
    visibility: hidden; /* Initially hidden */
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out; /* Transition for opacity and visibility */
}

.menu-popup.open {
    opacity: 1; /* Fade-in effect when open */
    visibility: visible; /* Display the content when open */
}

.menu-items a:hover {
   color: #828282; /* Change background color of bars on hover */
}
.menu-items a:active {
    color: #ffafaf; /* Change text color of menu links when clicked */
}


@media (max-width: 720px) {
    .original-links {
        display: none; /* Hide original links on small screens */
    }

    .burger-menu {
        display: flex;
        width: 100%;
        flex-direction: flex-end;
    }

    

   
}

