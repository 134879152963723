

.Home {
    background-color: #ffffff;
    text-align: center; /* Center aligns children horizontally */
    padding: 2px 0; /* Adding vertical padding to space things out */
}

.Home h1 {
    font-size: 3.5rem;
    color: #333333;
    font-family: 'CustomFont';
    letter-spacing: 0.75rem;
    padding-top: 17px; 
}

img {
    width: 493px;
    height: 428px;
    display: block; /* To center using margin: auto */
    margin: auto;
}

section {
    text-align: justify;
    width: 560px;
    margin: 0 auto; /* To center using margin: auto */
    font-family: 'CustomFont';
    font-size: 16px;
    color: #333333;
    line-height: 30px;
    padding: 40px;
    letter-spacing: 0.5px;
}

section a {
    color: #333333;
}

figure {
    text-align: left;
    width: 493px;
    margin: 0 auto; /* To center using margin: auto */
}
figcaption{
    font-family: 'CustomFont';
    font-size: 14px;
    color: #333333;
    line-height: 30px;
    
}
.IconsContainer {
  text-align: right;
    padding: 20px 0; 
    padding-right: 5em;
}

.IconsContainer .Icon {
    display: inline-block;
    margin-right: 10px; /* Add some space between the items if needed */
}

.Icon {
    width: 26px;
    height: 26px;
    margin-right: 15px;
}

/* Small screens (up to 600px) */
@media (max-width: 720px) {
    .Home {
        align-items: center; /* Add this property to center the content */
        
    }

    .Home h1 {
        font-size: 3.5rem;
        letter-spacing: 0.5rem;
    }

    img {
        width: 100%; /* Make images take full width */
        height: auto;
        display: block;
        margin: auto; /* Center the image horizontally */
    }
    figure {
        text-align: left;
        width: 80%;
    }
    section {
        width: 80%; /* Adjust the width */
        font-size: 16px;
        line-height: 24px;
        margin: 0 auto; /* Center the section horizontally */
        text-align: justify; /* Reset text alignment */
    }


    .Icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}


