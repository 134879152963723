.bookpagecontainer {
    display : flex;
    flex-direction: column;
    align-items: center;
    
}

.book {
    display: grid;
    grid-template-columns: 360px auto; /* Fixed width for carousel, remaining space for synopsis */
    margin: 8vh 20vh 1vh 20vh;
    width: 100%; /* Ensure the container takes full width */
    max-width: 960px; /* Limit the container's maximum width */
   
}
.image{
width:360px;
height:550px;
}
.crsl {
    width: 100%;
    height: auto;
    object-fit: cover;
    position: relative; /* or position: absolute; */
    z-index: 50;
}

.synopsis {
    width: 460px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    word-spacing: 0.04em; 
}

.outnow{
  margin-top: 30px;
  text-align: center;
  font-style: italic;
}

.buttonholder{
    margin-top: 30px;
    text-align: center;
}

.topcomment{
    font-family: 'CustomFont';
    font-size: 16px;
    margin:  70px 15px 0px 15px;
    color: rgb(50, 49, 49);
    text-align: center;
}

.buyedition{
    outline: none;
    border: 1px solid rgb(50, 49, 49);
    font-family: 'CustomFont';
    font-size: 16px;
    margin: 15px;
    padding: 9px;
color: white;
background-color: rgb(50, 49, 49);
}

.buyedition:hover {
    color: black;
    background-color: white;
    border: 1px solid black;
    
}

.quote{

    font-family: 'CustomFont';
    font-size: 16px;
    margin:  0px 15px 15px 15px;
    padding: 9px;
    color: rgb(50, 49, 49);
    text-align: center;
    line-height: 24px;
    letter-spacing: 0.1px;
    word-spacing: 0.04em; 
   
}


.quotegrid{
    display: flex;
   width:70%;
}


@media (max-width: 720px) {

.book{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:350px;
}
.synopsis {
    width: auto; /* Adjust the width to the content size */
}

.quotegrid{
    display: flex;
    flex-direction: column;
   width:70%;
}

}