.other {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.other h1 {
  font-size: 3.5rem;
  color: #333333;
  font-family: "CustomFont";
  letter-spacing: 0.75rem;
  padding-top: 17px;
  text-align: center;
  margin-bottom: 0px;
}

.other h2 {
  font-family: "CustomFont";
  font-size: 21px;
  color: rgb(50, 49, 49);
  letter-spacing: 4px;
  margin-bottom: 0px;
  font-weight: lighter;
}

.writingcontainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  text-align: center;
}
